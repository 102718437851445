<script setup>
import {useI18n} from "vue-i18n";
import {computed, ref, watchEffect} from "vue";
import axios from "axios";
import {params} from "@/config";
import i18n from "@/i18n";
import OrderCallButton from "@/components/OrderCallButton.vue";
import ConnectForm from "@/components/ConnectForm.vue";

const { t } = useI18n();

const props = defineProps(['isCallButton']);

const contactsInfo = ref({});

const formattedPhoneNum = (phone) => {
  return ('' + phone).replace(/\D/g, '');
};

const lang = computed(() => {
  return i18n.global.locale.value;
});

watchEffect(() => {
  getData();
})

async function getData() {
  await axios.get(`${params.api_url}/pages/contacts`, { params: {lang: lang.value }})
    .then(({ data }) => {
      contactsInfo.value = data;
    })
}

const showForm = ref(false);

const openForm = () => {
  showForm.value = true;
  document.body.classList.add('body__modal-open');
};

const closeForm = () => {
  showForm.value = false;
  document.body.classList.remove('body__modal-open');
};
</script>

<template>
  <table class="contacts-table">
    <tr class="contacts-table__row">
      <td class="contacts-table__field-title">
        {{ `${t("phone")}:` }}
      </td>

      <td class="contacts-table__field">
        <a href="tel:+380970714000" class="contacts-table__link ph-phone1">
          +380970714000
        </a>
      </td>
    </tr>

    <tr class="contacts-table__row">
      <td class="contacts-table__field-title">
        Viber:
      </td>

      <td class="contacts-table__field">
        <a
          :href="`viber://chat?number=%2B${formattedPhoneNum(contactsInfo['viber_phone_number'])}`"
          class="contacts-table__link"
        >
          {{ contactsInfo['viber_phone_number'] }}
        </a>
      </td>
    </tr>

    <tr class="contacts-table__row">
      <td class="contacts-table__field-title">
        Email:
      </td>

      <td class="contacts-table__field">
        <a
          :href="`mailto:${contactsInfo['email']}`"
          class="contacts-table__link"
        >
          {{ contactsInfo['email'] }}
        </a>
      </td>
    </tr>

    <tr class="contacts-table__row">
      <td class="contacts-table__field-title">
        {{ `${t("mainOffice")}:` }}
      </td>

      <td class="contacts-table__field">
        {{ contactsInfo['main_office_address'] }}
      </td>
    </tr>

    <tr class="contacts-table__row">
      <td class="contacts-table__field-title">
        {{ `${t("salesDepartments")}:` }}
      </td>

      <td class="contacts-table__field">
        <div v-html="contactsInfo['sales_office_adress']" />
      </td>
    </tr>

    <tr class="contacts-table__row">
      <td class="contacts-table__field-title">
        {{ `${t("workSchedule")}:` }}
      </td>

      <td class="contacts-table__field">
        <div v-html="contactsInfo['schedule']" />
      </td>
    </tr>
  </table>

  <div :class="{'with-call-button': isCallButton, 'contacts-table__btn': true}">
    <OrderCallButton class="contacts-button-without-margin" v-if="isCallButton" />

    <button @click="openForm" class="contacts-button__item">
      {{ t("sign_up") }}
    </button>
  </div>

  <Teleport to="body">
    <ConnectForm
      @close="closeForm"
      :show="showForm"
    />
  </Teleport>

</template>

<style scoped>
  .contacts-table {
    width: 530px;
    border-collapse: collapse;
  }

  .contacts-table__row {
    border-bottom: 1px dashed rgba(25, 25, 25, 0.1);
  }

  .contacts-table__row:last-child {
    border: none;
  }

  .contacts-table__field {
    padding: 20px 26px;

    font-size: 16px;
    font-weight: 400;
    line-height: 145%;
    color: var(--black);
  }

  .contacts-table__link {
    font-size: 16px;
    font-weight: 400;
    line-height: 145%;
    color: var(--black);
    text-decoration: none;
  }

  .contacts-table__field-title {
    display: flex;
    justify-content: flex-start;
    padding: 20px 26px;

    font-size: 16px;
    font-weight: 400;
    line-height: 145%;
    color: var(--blago-black);
    opacity: 0.5;
  }

  .contacts-table__field :deep(.contacts-table__text) {
    margin-bottom: 20px;
  }

  .contacts-table__field :deep(.contacts-table__text-bold) {
    font-weight: 700;
  }

  .with-call-button {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  .contacts-button__item {
    height: 58px;
    padding: 8px 16px;

    font-size: 16px;
    font-weight: 700;
    line-height: 145%;
    border-radius: 30px;
    cursor: pointer;

    background-color: var(--blago-white);
    border: 2px solid var(--blago-green);

    transition: all 0.3s;
  }

  .contacts-button__item:hover {
    background-color: var(--blago-green);
  }

  @media (max-width: 1396px) {
    .contacts-table {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    .contacts-table {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .contacts-table__field {
      padding: 20px;
    }
    .contacts-table__btn {
      text-align: center;
    }
  }
</style>
